import {flosIconSVG} from "../utils/svg-tag-generator";

const OldShapes: { [key: string]: string; } = {
    'basket': flosIconSVG(
        `<g transform="translate(0 .5)">
        <path d="M0 0h3.018a6.268 6.268 0 015.645 3.546l9.4 19.5h29.8M49.614 15.589H14.468M52.247 7.795H10.609"/>
        <circle cx="3.529" cy="3.529" r="3.529" transform="translate(14.468 29.034)"/>
        <circle cx="3.529" cy="3.529" r="3.529" transform="translate(41.658 29.034)"/>
    </g>`,
        '0 0 52.247 37.093'
    ),
    'check2': flosIconSVG(
        `<path d="M12,.6,5,9.94.65,6.2"/>`,
        '0 0 12.75 11.4'
    ),
    'child': flosIconSVG(
        `<g transform="translate(.5 .5)">
        <circle cx="5.25" cy="5.25" r="5.25" transform="translate(2.48)"/>
        <path d="M15.46 33.878V21.352a6.613 6.613 0 00-6.613-6.613H6.614A6.613 6.613 0 000 21.352v12.619M4.899 33.397v12.637M10.562 33.397v12.637"/>
    </g>`,
        '0 0 16.46 46.534'
    ),
    'crane-truck': flosIconSVG(
        `<g transform="translate(.5 .5)">
    <circle cx="4.505" cy="4.505" r="4.505" transform="translate(39.293 24.404)"/>
    <path d="M46.789 8.4a4.007 4.007 0 00-3.111-1.482h-8.674a2.134 2.134 0 00-2.137 2.134v19.854h4.741a6.189 6.189 0 016.188-6.185 6.188 6.188 0 016.187 6.185h1.323a3.418 3.418 0 003.418-3.418v-7.3z"/>
    <circle cx="4.505" cy="4.505" r="4.505" transform="translate(7.393 24.404)"/>
      <path d="M4.359 28.906H5.71a6.189 6.189 0 016.188-6.185H4.359z"/>
      <path d="M11.898 22.721a6.188 6.188 0 016.187 6.185h14.789v-6.185z"/>
    <rect width="5.814" height="3.298" rx="1.649" transform="translate(35.158 1.679)"/>
    <path d="M17.259 22.721L8.333 0"/>
    <path d="M30.067 22.734l-17.4-20.819A5.333 5.333 0 008.574 0H5.866a2.364 2.364 0 00-2.364 2.364v5.151h0a3.5 3.5 0 013.5 3.484 3.562 3.562 0 01-3.522 3.522 3.5 3.5 0 01-3.483-3.5"/>
    <path d="M47.812 18.443a2.158 2.158 0 001.7-3.492l-3.348-4.263a3.989 3.989 0 00-3.249-1.676h-3.622v8.378a1.038 1.038 0 001.037 1.039z"/>
  </g>`,
        '0 0 55.727 34.415'
    ),
    'group': flosIconSVG(
        `<g transform="translate(.5 6.931)">
            <ellipse cx="5.503" cy="5.479" rx="5.503" ry="5.479" transform="translate(4.283)"/>
            <path d="M19.572 33.792v-12.7a6.474 6.474 0 00-6.488-6.46H6.489A6.474 6.474 0 000 21.092v12.792"/>
        </g>
        <g transform="translate(16.709 .5)">
            <ellipse cx="5.503" cy="5.479" rx="5.503" ry="5.479" transform="translate(4.283)"/>
            <path d="M19.572 21.916v-.821a6.474 6.474 0 00-6.488-6.46H6.49a6.474 6.474 0 00-6.489 6.46v.821"/>
        </g>
        <g transform="translate(32.918 6.931)">
            <ellipse cx="5.503" cy="5.479" rx="5.503" ry="5.479" transform="translate(4.282)"/>
            <path d="M19.571 33.792v-12.7a6.474 6.474 0 00-6.489-6.46H6.488a6.474 6.474 0 00-6.489 6.46v12.792"/>
        </g>`,
        '0 0 52.989 40.818'
    ),
    'money-notes': flosIconSVG(
        `<g transform="translate(0 .5)">
        <path d="M56.276 21.658V9.591A9.591 9.591 0 0146.685 0H10.244A9.591 9.591 0 01.653 9.591v12.067a9.59 9.59 0 019.591 9.591h36.442a9.59 9.59 0 019.59-9.591zm-27.811 3.557a9.591 9.591 0 119.59-9.591 9.591 9.591 0 01-9.59 9.591z"/>
        <circle cx="9.591" cy="9.591" r="9.591" transform="translate(18.874 6.033)"/>
        <path d="M56.277 21.658a9.59 9.59 0 00-9.591 9.591h9.591zM56.277 9.591V0h-9.591a9.591 9.591 0 009.591 9.591zM10.244 0H.653v9.591A9.591 9.591 0 0010.244 0zM.653 21.658v9.591h9.591a9.59 9.59 0 00-9.591-9.591zM0 36.504h56.92"/>
    </g>`,
        '0 0 56.92 37.504'
    ),
    'pen': flosIconSVG(
        `<g stroke-linejoin="bevel">
    <path d="M8.085 44.41l-3.793 4.668L.499 44.41V.5h7.586z"/>
    <path d="M4.293 54.33v-5.252"/>
    <path d="M.5 7.5h13.566v16.049"/>
  </g>`,
        '0 0 14.567 54.33'
    ),
    'wheel': flosIconSVG(
        `<g transform="translate(-3.807 -3.266)">+
    <circle cx="3.974" cy="3.974" r="3.974" transform="translate(34.042 33.501)"/>
    <circle cx="33.499" cy="33.499" r="33.499" transform="translate(4.517 3.976)"/>
    <circle cx="21.535" cy="21.535" r="21.535" transform="translate(16.481 15.94)"/>
    <path d="M41.489,16.84s-1.857,11.6,1.882,13.7,13.142-2.615,13.142-2.615"/>
    <path d="M22.768,51.809s8.471-8.139,6.753-12.071-12.065-5.83-12.065-5.83"/>
    <path d="M19.249,28.466s10.648,4.961,13.695,1.94S34.025,17.05,34.025,17.05"/>
    <path d="M58.666,34.68s-11.6,1.838-12.44,6.046S52.795,52.4,52.795,52.4"/>
    <path d="M46.834,56.623s-5.142-10.562-9.411-10.136-9.257,9.688-9.257,9.688"/>
  </g>`,
        '0 0 68.418 68.418'
    )
};

export {OldShapes};
