import {descriptorConfig} from '../utils/descriptor-config';
import {TopdanmarkShapes} from './topdanmark-shapes-generated';
import {CustomShapes} from './custom-shapes';

const AliasedShapes: { [key: string]: string } = {};

Object.defineProperty(AliasedShapes, 'add', descriptorConfig(TopdanmarkShapes['circle-plus']));
Object.defineProperty(AliasedShapes, 'alarm-call', descriptorConfig(TopdanmarkShapes['security-alarm-02']));
Object.defineProperty(AliasedShapes, 'alarm-clock', descriptorConfig(TopdanmarkShapes['security-camera-02']));
Object.defineProperty(AliasedShapes, 'alarm', descriptorConfig(TopdanmarkShapes['security-alarm']));
Object.defineProperty(AliasedShapes, 'alarm2', descriptorConfig(TopdanmarkShapes['security-alarm']));
Object.defineProperty(AliasedShapes, 'animal-bull', descriptorConfig(TopdanmarkShapes['bull']));
Object.defineProperty(AliasedShapes, 'animal-cat', descriptorConfig(TopdanmarkShapes['cat']));
Object.defineProperty(AliasedShapes, 'animal-cow-02', descriptorConfig(TopdanmarkShapes['cow-02']));
Object.defineProperty(AliasedShapes, 'animal-dog', descriptorConfig(TopdanmarkShapes['dog']));
Object.defineProperty(AliasedShapes, 'arrow', descriptorConfig(TopdanmarkShapes['arrow-right']));
Object.defineProperty(AliasedShapes, 'assistant', descriptorConfig(TopdanmarkShapes['person-heart-call']));
Object.defineProperty(AliasedShapes, 'avatar-head', descriptorConfig(TopdanmarkShapes['chat-face']));
Object.defineProperty(AliasedShapes, 'avatar', descriptorConfig(TopdanmarkShapes['chat-bot']));
Object.defineProperty(AliasedShapes, 'baby-carrier', descriptorConfig(TopdanmarkShapes['stroller']));
Object.defineProperty(AliasedShapes, 'bag', descriptorConfig(TopdanmarkShapes['attache']));
Object.defineProperty(AliasedShapes, 'balance', descriptorConfig(TopdanmarkShapes['scale']));
Object.defineProperty(AliasedShapes, 'barbell', descriptorConfig(TopdanmarkShapes['health-weight']));
Object.defineProperty(AliasedShapes, 'bath-duck', descriptorConfig(TopdanmarkShapes['duck']));
Object.defineProperty(AliasedShapes, 'bed-light-stand', descriptorConfig(TopdanmarkShapes['lamp']));
Object.defineProperty(AliasedShapes, 'boy', descriptorConfig(TopdanmarkShapes['person-boy']));
Object.defineProperty(AliasedShapes, 'broken-heart', descriptorConfig(TopdanmarkShapes['heart-broken']));
Object.defineProperty(AliasedShapes, 'building', descriptorConfig(TopdanmarkShapes['house-flats']));
Object.defineProperty(AliasedShapes, 'bulb', descriptorConfig(TopdanmarkShapes['light-bulb']));
Object.defineProperty(AliasedShapes, 'cable', descriptorConfig(TopdanmarkShapes['chord']));
Object.defineProperty(AliasedShapes, 'cal', descriptorConfig(TopdanmarkShapes['calendar']));
Object.defineProperty(AliasedShapes, 'calendar-02-no-date', descriptorConfig(TopdanmarkShapes['calendar-no-date']));
Object.defineProperty(AliasedShapes, 'calendar-02', descriptorConfig(TopdanmarkShapes['calendar']));
Object.defineProperty(AliasedShapes, 'call-center', descriptorConfig(TopdanmarkShapes['person-call']));
Object.defineProperty(AliasedShapes, 'call-center', descriptorConfig(TopdanmarkShapes['person-call']));
Object.defineProperty(AliasedShapes, 'camera', descriptorConfig(TopdanmarkShapes['security-camera']));
Object.defineProperty(AliasedShapes, 'car-collision', descriptorConfig(TopdanmarkShapes['car-crash-two-cars']));
Object.defineProperty(AliasedShapes, 'car-damage', descriptorConfig(TopdanmarkShapes['car-crash-single']));
Object.defineProperty(AliasedShapes, 'car-glass', descriptorConfig(TopdanmarkShapes['car-front-damage']));
Object.defineProperty(AliasedShapes, 'car-sedan', descriptorConfig(TopdanmarkShapes['car-sedan-side']));
Object.defineProperty(AliasedShapes, 'car-side-stationcar', descriptorConfig(TopdanmarkShapes['car-stationcar-side']));
Object.defineProperty(AliasedShapes, 'car-side-van', descriptorConfig(TopdanmarkShapes['car-van-side']));
Object.defineProperty(AliasedShapes, 'car-small', descriptorConfig(TopdanmarkShapes['car-mini-side']));
Object.defineProperty(AliasedShapes, 'car2', descriptorConfig(TopdanmarkShapes['car-sedan-side']));
Object.defineProperty(AliasedShapes, 'carwash', descriptorConfig(TopdanmarkShapes['car-wash']));
Object.defineProperty(AliasedShapes, 'cast', descriptorConfig(TopdanmarkShapes['broken-leg']));
Object.defineProperty(AliasedShapes, 'charging-station', descriptorConfig(TopdanmarkShapes['electricity-case']));
Object.defineProperty(AliasedShapes, 'chat-sender-robot', descriptorConfig(CustomShapes['flos']));
Object.defineProperty(AliasedShapes, 'chat-sender-support', descriptorConfig(CustomShapes['flos']));
Object.defineProperty(AliasedShapes, 'check', descriptorConfig(TopdanmarkShapes['circle-check']));
Object.defineProperty(AliasedShapes, 'circle-exclamation', descriptorConfig(TopdanmarkShapes['info']));
Object.defineProperty(AliasedShapes, 'circle-question', descriptorConfig(TopdanmarkShapes['info']));
Object.defineProperty(AliasedShapes, 'circle-x', descriptorConfig(TopdanmarkShapes['circle-close']));
Object.defineProperty(AliasedShapes, 'city-sign', descriptorConfig(TopdanmarkShapes['sign-city']));
Object.defineProperty(AliasedShapes, 'clock-forward', descriptorConfig(TopdanmarkShapes['clock-around']));
Object.defineProperty(AliasedShapes, 'coffee', descriptorConfig(TopdanmarkShapes['hot-drink']));
Object.defineProperty(AliasedShapes, 'cogs', descriptorConfig(TopdanmarkShapes['gears']));
Object.defineProperty(AliasedShapes, 'computer-cloud', descriptorConfig(TopdanmarkShapes['screen-cloud']));
Object.defineProperty(AliasedShapes, 'computer', descriptorConfig(TopdanmarkShapes['computer-screen']));
Object.defineProperty(AliasedShapes, 'conference-call', descriptorConfig(TopdanmarkShapes['phone-circle']));
Object.defineProperty(AliasedShapes, 'contacts', descriptorConfig(TopdanmarkShapes['health-journal']));
Object.defineProperty(AliasedShapes, 'corn', descriptorConfig(TopdanmarkShapes['wheat']));
Object.defineProperty(AliasedShapes, 'court', descriptorConfig(TopdanmarkShapes['court-hammer']));
Object.defineProperty(AliasedShapes, 'cow', descriptorConfig(TopdanmarkShapes['cow-02']));
Object.defineProperty(AliasedShapes, 'cow2', descriptorConfig(TopdanmarkShapes['cow']));
Object.defineProperty(AliasedShapes, 'cow3', descriptorConfig(TopdanmarkShapes['bull']));
Object.defineProperty(AliasedShapes, 'credit-cards', descriptorConfig(TopdanmarkShapes['credit-card']));
Object.defineProperty(AliasedShapes, 'creditcard', descriptorConfig(TopdanmarkShapes['credit-card']));
Object.defineProperty(AliasedShapes, 'creditcards', descriptorConfig(TopdanmarkShapes['credit-card']));
Object.defineProperty(AliasedShapes, 'desktop-computer-ad', descriptorConfig(TopdanmarkShapes['laptop-ad']));
Object.defineProperty(AliasedShapes, 'desktop-computer-e-mail', descriptorConfig(TopdanmarkShapes['laptop-email']));
Object.defineProperty(AliasedShapes, 'desktop-computer-mail', descriptorConfig(TopdanmarkShapes['laptop-mail']));
Object.defineProperty(AliasedShapes, 'desktop-computer', descriptorConfig(TopdanmarkShapes['laptop']));
Object.defineProperty(AliasedShapes, 'diamond-ring', descriptorConfig(TopdanmarkShapes['ring']));
Object.defineProperty(AliasedShapes, 'direct-mail', descriptorConfig(TopdanmarkShapes['laptop-email']));
Object.defineProperty(AliasedShapes, 'document-1', descriptorConfig(TopdanmarkShapes['document-search']));
Object.defineProperty(AliasedShapes, 'easy-chair', descriptorConfig(TopdanmarkShapes['armchair']));
Object.defineProperty(AliasedShapes, 'edit-round', descriptorConfig(TopdanmarkShapes['circle-edit']));
Object.defineProperty(AliasedShapes, 'envelope', descriptorConfig(TopdanmarkShapes['mail']));
Object.defineProperty(AliasedShapes, 'error', descriptorConfig(TopdanmarkShapes['circle-close']));
Object.defineProperty(AliasedShapes, 'europa', descriptorConfig(TopdanmarkShapes['europe']));
Object.defineProperty(AliasedShapes, 'excluded', descriptorConfig(TopdanmarkShapes['circle-close']));
Object.defineProperty(AliasedShapes, 'expand-map', descriptorConfig(TopdanmarkShapes['expand']));
Object.defineProperty(AliasedShapes, 'face-chat', descriptorConfig(TopdanmarkShapes['chat-face']));
Object.defineProperty(AliasedShapes, 'first-prize', descriptorConfig(TopdanmarkShapes['medal']));
Object.defineProperty(AliasedShapes, 'food-fork-knife', descriptorConfig(TopdanmarkShapes['cutlery']));
Object.defineProperty(AliasedShapes, 'future', descriptorConfig(TopdanmarkShapes['screen-idea']));
Object.defineProperty(AliasedShapes, 'girl-02', descriptorConfig(TopdanmarkShapes['person-girl']));
Object.defineProperty(AliasedShapes, 'girl', descriptorConfig(TopdanmarkShapes['person-girl-teen']));
Object.defineProperty(AliasedShapes, 'globe2', descriptorConfig(TopdanmarkShapes['globe']));
Object.defineProperty(AliasedShapes, 'graph-down', descriptorConfig(TopdanmarkShapes['graph']));
Object.defineProperty(AliasedShapes, 'hand-pointing-right', descriptorConfig(TopdanmarkShapes['hand-pointing']));
Object.defineProperty(AliasedShapes, 'hand', descriptorConfig(TopdanmarkShapes['hand-wave']));
Object.defineProperty(AliasedShapes, 'head', descriptorConfig(TopdanmarkShapes['face']));
Object.defineProperty(AliasedShapes, 'heart-rate', descriptorConfig(TopdanmarkShapes['health-heartbeat']));
Object.defineProperty(AliasedShapes, 'holding-hands', descriptorConfig(TopdanmarkShapes['hand-holding']));
Object.defineProperty(AliasedShapes, 'hot-drink-cup', descriptorConfig(TopdanmarkShapes['hot-drink']));
Object.defineProperty(AliasedShapes, 'house', descriptorConfig(TopdanmarkShapes['house-sun']));
Object.defineProperty(AliasedShapes, 'house-sunny', descriptorConfig(TopdanmarkShapes['house-sun']));
Object.defineProperty(AliasedShapes, 'hq', descriptorConfig(TopdanmarkShapes['house-tree']));
Object.defineProperty(AliasedShapes, 'identificer', descriptorConfig(TopdanmarkShapes['health-search']));
Object.defineProperty(AliasedShapes, 'included', descriptorConfig(TopdanmarkShapes['circle-check']));
Object.defineProperty(AliasedShapes, 'information-i', descriptorConfig(TopdanmarkShapes['info']));
Object.defineProperty(AliasedShapes, 'info-white', descriptorConfig(TopdanmarkShapes['info']));
Object.defineProperty(AliasedShapes, 'ipad-vertical-ad', descriptorConfig(TopdanmarkShapes['ipad-portrait-ad']));
Object.defineProperty(AliasedShapes, 'ipad-vertical', descriptorConfig(TopdanmarkShapes['ipad-portrait']));
Object.defineProperty(AliasedShapes, 'knife-fork', descriptorConfig(TopdanmarkShapes['cutlery']));
Object.defineProperty(AliasedShapes, 'locked', descriptorConfig(TopdanmarkShapes['lock']));
Object.defineProperty(AliasedShapes, 'loesoere', descriptorConfig(TopdanmarkShapes['furniture']));
Object.defineProperty(AliasedShapes, 'magnifying-glass', descriptorConfig(TopdanmarkShapes['search']));
Object.defineProperty(AliasedShapes, 'man', descriptorConfig(TopdanmarkShapes['person-man']));
Object.defineProperty(AliasedShapes, 'marriag-rings', descriptorConfig(TopdanmarkShapes['ring-marriage']));
Object.defineProperty(AliasedShapes, 'mason', descriptorConfig(TopdanmarkShapes['bricklayer-wall']));
Object.defineProperty(AliasedShapes, 'mc', descriptorConfig(TopdanmarkShapes['motorbike']));
Object.defineProperty(AliasedShapes, 'meeting', descriptorConfig(TopdanmarkShapes['table-chairs']));
Object.defineProperty(AliasedShapes, 'minus', descriptorConfig(TopdanmarkShapes['circle-minus']));
Object.defineProperty(AliasedShapes, 'mobile-message', descriptorConfig(TopdanmarkShapes['smart-phone-chat']));
Object.defineProperty(AliasedShapes, 'money-bag-kroner', descriptorConfig(TopdanmarkShapes['money-bag-kr']));
Object.defineProperty(AliasedShapes, 'money-bag', descriptorConfig(TopdanmarkShapes['money-bag-dollar']));
Object.defineProperty(AliasedShapes, 'money-note', descriptorConfig(TopdanmarkShapes['bill']));
Object.defineProperty(AliasedShapes, 'money-single', descriptorConfig(TopdanmarkShapes['bill']));
Object.defineProperty(AliasedShapes, 'money-stack', descriptorConfig(TopdanmarkShapes['bills']));
Object.defineProperty(AliasedShapes, 'movables', descriptorConfig(TopdanmarkShapes['furniture']));
Object.defineProperty(AliasedShapes, 'my-car', descriptorConfig(TopdanmarkShapes['car-mini-side']));
Object.defineProperty(AliasedShapes, 'negative', descriptorConfig(TopdanmarkShapes['circle-close']));
Object.defineProperty(AliasedShapes, 'ok-green', descriptorConfig(TopdanmarkShapes['circle-check']));
Object.defineProperty(AliasedShapes, 'open', descriptorConfig(TopdanmarkShapes['unlock']));
Object.defineProperty(AliasedShapes, 'outdoor', descriptorConfig(TopdanmarkShapes['ipad-portrait']));
Object.defineProperty(AliasedShapes, 'paper-1', descriptorConfig(TopdanmarkShapes['newspaper']));
Object.defineProperty(AliasedShapes, 'paper-2', descriptorConfig(TopdanmarkShapes['newspaper-folded']));
Object.defineProperty(AliasedShapes, 'parasol', descriptorConfig(TopdanmarkShapes['sunshade']));
Object.defineProperty(AliasedShapes, 'parking-damage', descriptorConfig(TopdanmarkShapes['parking-sign-broken']));
Object.defineProperty(AliasedShapes, 'parking', descriptorConfig(TopdanmarkShapes['parking-sign']));
Object.defineProperty(AliasedShapes, 'person-+', descriptorConfig(TopdanmarkShapes['person-many']));
Object.defineProperty(AliasedShapes, 'person-care', descriptorConfig(TopdanmarkShapes['person-heart-call']));
Object.defineProperty(AliasedShapes, 'person-closeup', descriptorConfig(TopdanmarkShapes['person-man']));
Object.defineProperty(AliasedShapes, 'persons', descriptorConfig(TopdanmarkShapes['person-two']));
Object.defineProperty(AliasedShapes, 'persons-many', descriptorConfig(TopdanmarkShapes['person-many']));
Object.defineProperty(AliasedShapes, 'pin', descriptorConfig(TopdanmarkShapes['map-pin']));
Object.defineProperty(AliasedShapes, 'pipe', descriptorConfig(TopdanmarkShapes['pipes']));
Object.defineProperty(AliasedShapes, 'place', descriptorConfig(TopdanmarkShapes['map-pin']));
Object.defineProperty(AliasedShapes, 'plane-ticket', descriptorConfig(TopdanmarkShapes['ticket']));
Object.defineProperty(AliasedShapes, 'plus-circle', descriptorConfig(TopdanmarkShapes['circle-plus']));
Object.defineProperty(AliasedShapes, 'positive', descriptorConfig(TopdanmarkShapes['circle-check']));
Object.defineProperty(AliasedShapes, 'rectangle', descriptorConfig(TopdanmarkShapes['warning-triangle']));
Object.defineProperty(AliasedShapes, 'rifle', descriptorConfig(TopdanmarkShapes['weapon']));
Object.defineProperty(AliasedShapes, 'remove', descriptorConfig(TopdanmarkShapes['circle-minus']));
Object.defineProperty(AliasedShapes, 'school', descriptorConfig(TopdanmarkShapes['house-tree']));
Object.defineProperty(AliasedShapes, 'science', descriptorConfig(TopdanmarkShapes['chemistry']));
Object.defineProperty(AliasedShapes, 'screen-light', descriptorConfig(TopdanmarkShapes['screen-idea-bright']));
Object.defineProperty(AliasedShapes, 'service', descriptorConfig(TopdanmarkShapes['car-service']));
Object.defineProperty(AliasedShapes, 'sign-document', descriptorConfig(TopdanmarkShapes['document-sign']));
Object.defineProperty(AliasedShapes, 'smiley-angry', descriptorConfig(TopdanmarkShapes['smiley-sad']));
Object.defineProperty(AliasedShapes, 'sofa', descriptorConfig(TopdanmarkShapes['couch']));
Object.defineProperty(AliasedShapes, 'stethoscope', descriptorConfig(TopdanmarkShapes['health-stethoscope']));
Object.defineProperty(AliasedShapes, 'surveillance', descriptorConfig(TopdanmarkShapes['security-camera']));
Object.defineProperty(AliasedShapes, 'table-and-chairs', descriptorConfig(TopdanmarkShapes['table-chairs']));
Object.defineProperty(AliasedShapes, 'to-do', descriptorConfig(TopdanmarkShapes['document-check-mark']));
Object.defineProperty(AliasedShapes, 'trashcan-1', descriptorConfig(TopdanmarkShapes['trash-can']));
Object.defineProperty(AliasedShapes, 'trashcan-2', descriptorConfig(TopdanmarkShapes['circle-trash']));
Object.defineProperty(AliasedShapes, 'travel-eiffel-tower', descriptorConfig(TopdanmarkShapes['eiffel-tower']));
Object.defineProperty(AliasedShapes, 'travel-globe-destinations', descriptorConfig(TopdanmarkShapes['globe-destinations']));
Object.defineProperty(AliasedShapes, 'travel-globe', descriptorConfig(TopdanmarkShapes['globe']));
Object.defineProperty(AliasedShapes, 'travel-globus', descriptorConfig(TopdanmarkShapes['globe-stand']));
Object.defineProperty(AliasedShapes, 'travel-plane', descriptorConfig(TopdanmarkShapes['plane']));
Object.defineProperty(AliasedShapes, 'travel-scenery', descriptorConfig(TopdanmarkShapes['cake']));
Object.defineProperty(AliasedShapes, 'travel-suitcase-3', descriptorConfig(TopdanmarkShapes['carry-on']));
Object.defineProperty(AliasedShapes, 'travel-ticket', descriptorConfig(TopdanmarkShapes['ticket']));
Object.defineProperty(AliasedShapes, 'tree', descriptorConfig(TopdanmarkShapes['three']));
Object.defineProperty(AliasedShapes, 'trowel', descriptorConfig(TopdanmarkShapes['bricklayer']));
Object.defineProperty(AliasedShapes, 'vacation-backpack', descriptorConfig(TopdanmarkShapes['backpack']));
Object.defineProperty(AliasedShapes, 'vacation-house', descriptorConfig(TopdanmarkShapes['house-vacation']));
Object.defineProperty(AliasedShapes, 'vacation-sunshade', descriptorConfig(TopdanmarkShapes['sunshade']));
Object.defineProperty(AliasedShapes, 'vacation-umbrella', descriptorConfig(TopdanmarkShapes['umbrella']));
Object.defineProperty(AliasedShapes, 'van', descriptorConfig(TopdanmarkShapes['car-van-side']));
Object.defineProperty(AliasedShapes, 'wand', descriptorConfig(TopdanmarkShapes['magic-wand']));
Object.defineProperty(AliasedShapes, 'weather-cloud', descriptorConfig(TopdanmarkShapes['cloud']));
Object.defineProperty(AliasedShapes, 'weather-star', descriptorConfig(TopdanmarkShapes['star']));
Object.defineProperty(AliasedShapes, 'weather-sun', descriptorConfig(TopdanmarkShapes['sun']));
Object.defineProperty(AliasedShapes, 'webcam', descriptorConfig(TopdanmarkShapes['security-camera-02']));
Object.defineProperty(AliasedShapes, 'wedding-rings', descriptorConfig(TopdanmarkShapes['ring-marriage']));
Object.defineProperty(AliasedShapes, 'woman', descriptorConfig(TopdanmarkShapes['person-woman']));
Object.defineProperty(AliasedShapes, 'woman2', descriptorConfig(TopdanmarkShapes['person-young-woman']));
Object.defineProperty(AliasedShapes, 'young-woman', descriptorConfig(TopdanmarkShapes['person-young-woman']));

export {AliasedShapes};
