import {flosIconSVG} from "../utils/svg-tag-generator";

/**
 * These shapes replaces the ones in TopdanmarkShapes, because we have the need to style them a little different that the ones generated from the figma exports
 * If adding new shapes, remember to add the shape key to the shapesToSkip array in /scripts/generate-shapes.mjs
 */
const CustomShapes = {
    'avatar-colored': flosIconSVG(
        `<path d="M21.25.75a20.5 20.5 0 00-20.5 20.5 20.423 20.423 0 005.27 13.719l-1.85 7.923.434.377 10.325-2.517a20.471 20.471 0 006.321 1 20.5 20.5 0 0020.5-20.5A20.5 20.5 0 0021.25.75z" fill="#fff" stroke="#75bee9" stroke-miterlimit="10" stroke-width="1.5"/>
  <g transform="translate(6.716 14.293)">
    <circle cx="2.022" cy="2.022" r="2.022" transform="translate(25.023)" fill="#131947"/>
    <circle cx="2.022" cy="2.022" r="2.022" fill="#131947"/>
    <path d="M1.754 11.152a13.452 13.452 0 0012.78 9.249" fill="none" stroke="#131947" stroke-miterlimit="10" stroke-width="2.835"/>
  </g>`,
        '0 0 42.5 44.089'
    ),
    'tombstone': flosIconSVG('<path d="M38.3 23v4.3h-5.8V40h-4.2V27.3h-5.9V23h5.9v-7h4.2v7h5.8z"></path><path d="M13.8 49.4L12.3 19c-.5-9.4 7.6-17 18.1-17C40.9 2 49 9.6 48.5 19l-1.6 30.4M11 53.1v-3.7h38.7v3.7M52.7 53.1H8v7.3h44.7v-7.3z"></path>', '0 0 60 60'),
    'truck-trailer': flosIconSVG('<g clip-path="url(#clip0)"><path fill-rule="evenodd" d="M18.5 13.5v30h3a5 5 0 0110 0h6a5 5 0 0110 0h4v-30a1 1 0 00-1-1h-31a1 1 0 00-1 1zM54.5 13.5v30h3a5 5 0 0110 0h6a5 5 0 0110 0h4v-30a1 1 0 00-1-1h-31a1 1 0 00-1 1zM18.5 20.5H8.326L.5 30.5v13h4a5 5 0 0110 0h4v-23z" clip-rule="evenodd"></path><circle cx="26.5" cy="43.5" r="4.5"></circle><circle cx="62.5" cy="43.5" r="4.5"></circle><circle cx="9.5" cy="43.5" r="4.5"></circle><circle cx="42.5" cy="43.5" r="4.5"></circle><path d="M13 29.5v-6H6l-4.5 6H13zM52 40.5h2"></path></g><defs><clipPath id="clip0"><path d="M0 0h60v60H0z"></path></clipPath></defs>', '0 0 60 60'),
    'flos': flosIconSVG(`<path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M32.754 37.2787C32.754 37.2787 38.5356 37.8968 42.5811 41.5226C46.6266 45.1484 47.6585 45.8571 50.32 46.2691C52.9815 46.6811 54.4883 47.2827 53.4811 48.7413C52.4738 50.1998 49.6566 53.29 41.4264 51.4029C33.1962 49.5159 32.2298 43.5168 32.0579 42.2313C31.8859 40.9457 31.4519 37.0315 32.754 37.2787Z" fill="#62ADDA"/>
<path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M27.1358 26.253C27.1358 26.253 21.6244 26.7969 19.7654 25.4289C17.9065 24.061 19.2577 22.0586 16.7108 19.4875C14.1639 16.9165 10.5443 15.0377 10.3231 11.5684C10.1497 8.27849 10.3061 4.97937 10.7899 1.721C11.1011 0.501404 13.2467 -0.108394 15.0074 1.25129C16.7681 2.61097 25.9319 8.08267 26.5543 9.88734C27.3077 12.0463 31.0011 11.2965 30.3705 14.296C29.83 17.0154 25.5061 19.9737 27.1358 26.253Z" fill="#62ADDA"/>
<path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M30.428 24.1348C30.428 24.1348 30.0841 16.9161 32.156 15.2927C34.2279 13.6694 38.5355 8.33776 40.9513 5.72552C43.3672 3.11328 47.2161 -0.454861 50.2789 0.0478094C53.3417 0.55048 54.3736 0.937786 55.3317 1.94313C56.2899 2.94847 57.3381 3.66539 54.4063 4.25047C51.4746 4.83554 48.2643 4.84378 46.5118 7.20057C44.7593 9.55735 40.6565 12.0377 39.2643 17.2705C37.8721 22.5032 36.5946 27.0025 34.7602 26.8047C34.7602 26.8047 30.4117 25.4121 30.428 24.1348Z" fill="#62ADDA"/>
<path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M38.4617 24.1353C40.9676 21.5231 38.691 22.652 44.4236 20.5919C50.1561 18.5318 53.3663 14.1643 53.7184 12.714C54.0706 11.2636 55.905 7.25051 58.2225 6.94561C60.5401 6.64072 64.7331 8.13224 63.8896 12.4997C63.2805 15.11 62.0648 17.5376 60.3422 19.5832C58.6196 21.6288 56.4394 23.2341 53.9805 24.2672C48.5592 26.4427 45.4472 27.6128 41.6965 27.5634C37.9458 27.5139 35.9558 26.7558 38.4617 24.1353Z" fill="#62ADDA"/>
<path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M37.3806 33.6194C36.8974 33.1085 36.6435 32.1361 38.1995 31.0236C40.7055 29.2519 45.5699 27.1836 52.8584 29.9936C60.1469 32.8036 65.3144 34.7896 63.1606 36.8991C58.3207 41.6292 58.3617 43.3432 60.9822 46.7877C63.439 50.0015 58.3535 51.0316 58.3535 51.0316C58.3535 51.0316 55.7165 51.6496 54.6928 47.9661C53.6692 44.2826 53.055 39.5773 50.6555 38.2835C44.4889 34.9461 38.2077 34.5011 37.3806 33.6194Z" fill="#62ADDA"/>
<path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M24.3599 38.803C23.5726 38.5487 22.7288 38.531 21.9317 38.752C21.1346 38.973 20.4188 39.4231 19.8722 40.0473C18.2998 41.6954 12.5018 47.3072 11.6828 55.3747C10.8639 63.4421 14.5982 62.4285 16.408 62.3214C18.2179 62.2143 19.389 62.3214 19.8722 63.3927C20.3553 64.4639 22.5664 64.1013 23.8767 62.4368C25.9136 60.0292 28.1019 57.7556 30.4282 55.6301C32.2134 54.0397 33.1634 49.3261 32.1725 46.7963C31.3536 44.7197 30.6984 44.3818 30.5346 44.3241C30.4937 44.3241 30.4773 44.3241 30.4691 44.3241C30.4609 44.3241 27.4882 44.8845 26.2025 48.8564C24.9168 52.8283 22.4272 56.6602 21.4036 56.8085C20.3799 56.9568 17.0141 58.1352 17.8903 55.4818C18.7666 52.8283 22.7548 49.7134 24.3844 47.6533C26.0141 45.5932 27.4554 43.6237 27.3162 41.3081C27.177 38.9925 24.3599 38.803 24.3599 38.803Z" fill="#62ADDA"/>
<path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.85625 38.3575C7.19472 37.4593 3.77159 36.3139 2.48586 34.7811C1.20014 33.2484 -1.24026 26.1451 0.757929 25.1809C2.75612 24.2168 3.05913 23.0384 5.67152 24.1673C8.28391 25.2963 9.76617 24.7689 12.5669 25.1809C15.2851 25.5776 17.926 26.3948 20.3959 27.6036L21.7554 28.4277C21.7554 28.4277 24.507 30.2324 21.7554 30.7021C19.0037 31.1718 16.6124 30.4137 14.6634 31.0152C12.7143 31.6168 10.5196 33.0918 13.1566 33.3885C15.7935 33.6851 18.2503 32.4326 19.2658 33.2402C20.2812 34.0477 22.1894 34.8883 19.8718 36.5364C17.2349 38.5388 12.4768 39.264 9.85625 38.3575Z" fill="#62ADDA"/>
<path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M24.7614 30.4965C24.139 31.3206 23.2627 32.7545 23.3201 33.7928C23.5371 35.0062 24.1072 36.1275 24.9579 37.0148C25.855 37.4717 26.8755 37.6221 27.8651 37.4433C28.9123 37.1595 29.9256 36.7616 30.887 36.2567C31.8682 35.5152 32.7492 34.6482 33.5076 33.6774C34.2192 32.6584 34.6462 31.4661 34.7442 30.2246C34.6623 29.4006 34.7441 28.2304 33.655 27.5052C32.7583 26.9522 31.7402 26.6296 30.6904 26.5658C27.3165 26.4257 25.2118 29.928 24.7614 30.4965Z" fill="#62ADDA"/>
`, '0 0 64 64'),
};

export {CustomShapes};
