import { FlosIconsApi } from './flos-icons-api';
import { FlosIconElement } from './flos-icons-element';
import { TopdanmarkShapes } from './shapes/topdanmark-shapes-generated';
import { CustomShapes } from './shapes/custom-shapes';
import { AnimatedShapes } from './shapes/animated-shapes';
import { AliasedShapes } from './shapes/aliased-shapes';
import { OldShapes } from './shapes/old-shapes';

declare var window: Window;
interface Window {
  FlosIcons: FlosIconsApi;
}
if (typeof window !== 'undefined') {
  window.FlosIcons = window.FlosIcons || FlosIconsApi.instance;
}

window.FlosIcons.add(TopdanmarkShapes);
window.FlosIcons.add(CustomShapes);
window.FlosIcons.add(AnimatedShapes);
window.FlosIcons.add(AliasedShapes);
window.FlosIcons.add(OldShapes);

if (!customElements.get('flos-icon')) {
  // @ts-ignore
  customElements.define('flos-icon', FlosIconElement);
}

const { FlosIcons } = window;
export { FlosIcons };
